<template>
    <div>
      <Navbar-section  titleClass="ml-4 " section="Clientes" />
      <div class="flex flex-col justify-center my-2 mx-4">
            <!-- tabla expres -->
            <div class=" flex justify-center ">
                <TableExpress
                model="equipments"
                keySearch="search"
                :response="equipments"
                :repository="equipmentsIndexLocalRepo"
                :visibleWithOutResponse="false"
                :formFilter="formFilter"
                :loaderSearch="true"
                @notFound="notFound($event)"
                @completeFilters="completeFilters($event)"
                @setResponse="equipments = $event"
                >
                  <template v-slot:columns>
                    <Column key="identificacion" :title="$t('Identificacion')" />
                    <Column key="empresa" :title="$t('Empresa')" />
                    <Column key="equipment_state" title="Estado">
                      <template v-slot:action="{content}">
                        <div :style="`background:${content.equipment_state?.color};color:white;`"
                        :class="['w-22 rounded-lg text-white text-xs p-1 mx-1']">
                          {{content.equipment_state?.name.toLowerCase()}}</div>
                      </template>
                    </Column>
                    <Column key="nombre_cliente" :title="$t('Nombre')" />
                    <Column key="direccion" :title="$t('Direccion')" />
                    <Column key="localidad" :title="$t('Localidad')" />
                    <Column key="codigo_postal" :title="$t('Cp')"  />
                    <Column key="serie" :title="$t('Serie')" />
                    <Column key="terminal" :title="$t('Terminal')" />
                    <Column key="serie_base" :title="$t('Serie base')" />
                    <Column key="tarjeta" :title="$t('Tarjeta')" />
                    <Column key="id" :title="$t('reff')" />
                  </template>
                </TableExpress>
            </div>
            <!-- sin resultados -->
            <div v-if="emptyResult" class="w-full bg-white h-28 flex flex justify-center items-center">
                <div class="text-lg font-bold flex flex-col">
                    <div>{{messageResult}}</div>
                    <div class="mx-auto my-2">
                        <font-awesome-icon class="fill-current h-8 w-6 text-blue-400" style="font-size:30px;" icon="exclamation-circle" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router';
// components
// utilidades
import { equipmentsIndexRepo } from '@/repositories/Core/EquipmentsRepository';
import usualHook from '@/hooks/core/usualHook';
import { EQUIPMENT_STATES } from '@/services/Utils/State';

export default {
  setup() {
    const route = useRoute();
    const instance = ref({});

    onMounted(() => {
      instance.value = getCurrentInstance().appContext.config.globalProperties;
    });

    const emptyResult = ref(false);
    const messageResult = ref('No se han encontrado resultados');
    const notFound = (flag) => {
      emptyResult.value = flag;
      messageResult.value = 'No se han encontrado resultados';
    };

    const equipments = ref([]);
    const currentEquipment = ref({});
    const equipmentsIndexLocalRepo = async (params) => {
      const response = await equipmentsIndexRepo({ ...params, ...{ cancel: ['recoverable', 'template', 'exists'] } });
      return response;
    };

    const confirmOpenTransaction = ref(false);

    const {
      equipment_states, getEquipmentStates, getEnterprises, enterprises,
    } = usualHook();
    getEquipmentStates();
    getEnterprises();

    const loading = ref(false);
    const filters = ref({});
    const formFilter = ref(
      {
        equipment_state: {
          placeholder: 'Estado',
          value: null,
          options: equipment_states,
          type: 'select',
        },
        enterprise: {
          placeholder: 'Empresa',
          value: null,
          options: enterprises,
          type: 'select',
        },
        createdFrom: {
          placeholder: 'Desde el dia',
          value: null,
          type: 'date',
        },
        createdTo: {
          placeholder: 'Hasta el dia',
          value: null,
          type: 'date',
        },

      },
    );
    const completeFilters = async (data) => {
      Object.keys(formFilter.value).forEach((key) => {
        formFilter.value[key].value = data[key];
      });
      filters.value = data;
    };

    return {
      route,
      equipmentsIndexLocalRepo,
      equipments,
      loading,
      confirmOpenTransaction,
      formFilter,
      currentEquipment,
      EQUIPMENT_STATES,
      emptyResult,
      messageResult,
      notFound,
      completeFilters,
    };
  },
};
</script>

<style>

.run-title{
  width:100%;
  max-width: 180px;

}

</style>
