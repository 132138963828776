'strict mode';

import $http from '@/services/Api/AxiosInstance';
import { appendForm } from '@/services/Utils/Form';

export const equipmentManagementIndexRepo = async (params = {}, exportExcel = false) => {
  let result = {};
  try {
    const url = !exportExcel ? 'v1/equipmentsManagement' : 'v1/exports/equipmentsManagement';
    const response = await $http.get(url, { params });
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const generateOrderRepo = async () => {
  let result = {};
  try {
    const response = await $http.post('v1/equipmentsManagement/order');
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const saveEquipmentRepo = async (params = {}) => {
  let result = {};
  try {
    const response = await $http.post('v1/equipmentsManagement/save', params);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const confirmTransactionRepo = async (params = {}) => {
  let result = {};
  const formData = await appendForm(params);
  try {
    const response = await $http.post('v1/equipmentsManagement/confirm/transaction', formData);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const getTransactionRepo = async (order, hash) => {
  let result = {};
  try {
    const response = await $http.get(`v1/equipmentsManagement/transaction/${order}/${hash}`);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const deleteTransactionRepo = async (order, hash, finished = 0) => {
  let result = {};
  try {
    const response = await $http.delete(`v1/equipmentsManagement/transaction/${order}/${hash}/${finished}`);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const removeEquipmentTransactionRepo = async (order, hash, equipment_management_id) => {
  let result = {};
  try {
    const response = await $http.delete(`v1/equipmentsManagement/delete/${order}/${hash}/${equipment_management_id}`);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const sendReceiptRepo = async (order, hash, contact, type) => {
  let result = {};
  try {
    const response = await $http.get(`v1/equipmentsManagement/receipt/${order}/${hash}/${contact}/${type}`);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const sendNoticeRepo = async (params) => {
  let result = {};
  try {
    const response = await $http.post('v1/equipmentsManagement/notice', params);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const getOrderImagesRepo = async (order) => {
  let result = {};
  try {
    const response = await $http.get(`v1/managementOrders/${order}`);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const deleteOrderImageRepo = async (id) => {
  let result = {};
  try {
    const response = await $http.delete(`v1/managementOrders/${id}`);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const saveManagementOrdersImageRepo = async (params) => {
  let result = {};
  const formData = await appendForm(params);
  try {
    const response = await $http.post('v1/managementOrders/image', formData);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};
