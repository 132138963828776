<template>
  <Base-modal
    :modalOverlay="true"
    @exit="exit()"
    :modal="true"
    :headingTitle="$t('Equipos en transaccion')"
    size="lg"
    >
    <template #content>
      <div class="m-2" v-if="equipments.length === 0">
        No hay equipos en transaccion
      </div>
      <div class="m-2 h-56 flex justify-center items-center flex-col" v-if="equipments.length === 0">
          <div class="flex justify-center items-center mb-4">
            <img style="width:25%;" :src="`${$apiDevuelvoya}/images/shared/open-box.png`" >
          </div>
      </div>
      <div v-if="equipments.length > 0" class="mx-2">
        <span class="text-gray-400"> {{equipments.length}} equipos </span>
      </div>
      <LoaderContinue v-if="loadingRemove"/>
      <BaseCard
      :response="equipments"
      :cardSource="cardTransaction"
      >
        <template v-slot:accesories="{item}">
          <template v-if="item.equipment_state_id === EQUIPMENT_STATES.AUTORIZAR || item.equipment_state_id === EQUIPMENT_STATES.RECUPERADO">
            <div class="c-col-s-12" v-for="(data,index) in item.accesories" :key="index">
              <span class="font-bold">{{data.accessory.name}}</span> <span class="text-gray-500">{{data.value === 1 ? 'Si' : 'No'}}</span>
            </div>
          </template>
          <!-- si accesorios -->
          <template v-else><div></div></template>
        </template>
        <template v-slot:lastBlock="{item}">
          <div>
            <font-awesome-icon class="cursor-pointer" @click="removeTransactionEquipment(item)" icon="trash-alt" />
          </div>
        </template>
      </BaseCard>
    </template>
  </Base-modal>
</template>

<script>
import {
  ref, computed, onMounted, getCurrentInstance,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import LoaderContinue from '@/components/General/LoaderContinue.vue';
import { getTransactionRepo, removeEquipmentTransactionRepo } from '@/repositories/Core/EquipmentManagementRepository';
import { hasLocalStorage, objEmpty } from '@/services/Utils/Objects';
import { TYPE_TEMPLATES } from '@/services/Utils/Templates';
import { EQUIPMENT_STATES } from '@/services/Utils/State';

export default {
  components: {
    LoaderContinue,
  },
  setup(_, { emit }) {
    const store = useStore();
    const equipments = computed(() => store.state.equipments.equipments);
    const agreement = computed(() => store.state.equipments.agreement);
    const equipmentAvailables = computed(() => store.state.equipments.equipmentAvailables);
    const setPropEquipment = async (payload) => {
      const prop = await store.dispatch('equipments/setPropEquipment', payload);
      return prop;
    };

    const route = useRoute();
    const router = useRouter();

    const instance = ref({});
    onMounted(() => {
      instance.value = getCurrentInstance().appContext.config.globalProperties;
    });

    const exit = () => {
      if (agreement.value) {
        router.push({ name: 'agreementManagement' });
      } else {
        router.push({ name: 'equipmentManagement' });
      }
    };

    const loadingInTransaction = ref(false);
    const getEquipmentsInTransaction = async () => {
      loadingInTransaction.value = true;
      const { order, hash } = route.params;
      const response = await getTransactionRepo(order, hash);
      const { transaction: transactionAlias } = response;
      if (transactionAlias && transactionAlias.length > 0) {
        await setPropEquipment({ key: 'equipments', value: transactionAlias });
      }
      loadingInTransaction.value = false;
    };

    if (equipments.value.length === 0) {
      /* eslint no-use-before-define: ["error", { "variables": false }] */
      getEquipmentsInTransaction();
    }

    const loadingRemove = ref(false);
    const removeTransactionEquipment = async (equipment) => {
      if (!loadingRemove.value) {
        loadingRemove.value = true;
        const { order, hash } = route.params;
        const response = await removeEquipmentTransactionRepo(order, hash, equipment.id);
        const { transaction: transactionAlias } = response;
        if (transactionAlias) {
          instance.value.$toast.success('Eliminado', {
            duration: 1500,
            dismissible: true,
          });

          if (equipment.equipment_state_id !== EQUIPMENT_STATES.AUTORIZAR) {
            const remove = equipments.value.filter(({ id }) => id !== equipment.id);
            await setPropEquipment({ key: 'equipments', value: remove });
            localStorage.setItem('equipments', JSON.stringify(remove));
            // traer equipos originales guardados en la transaccion para restablecerlos como equipos disponibles
            const originalEquipments = await hasLocalStorage({ key: 'originalEquipments' });
            if (originalEquipments) {
              const restoreToAvailable = originalEquipments.filter((val) => val.id === equipment.equipment_id);
              if (restoreToAvailable.length > 0) {
                await setPropEquipment({ key: 'equipmentAvailables', value: [...equipmentAvailables.value, ...restoreToAvailable] });
              }
            }
            emit('addCounter');
          } else {
            const remove = equipments.value.filter(({ id }) => id !== equipment.id && equipment.equipment_state_id === EQUIPMENT_STATES.AUTORIZAR);
            await setPropEquipment({ key: 'equipments', value: remove });
            localStorage.setItem('equipments', JSON.stringify(remove));
          }

          if (equipments.value.length === 0) {
            exit();
          }
          loadingRemove.value = false;
        } else {
          instance.value.$toast.error('No se elimino correctamente', {
            duration: 1500,
            dismissible: true,
          });
          loadingRemove.value = false;
        }
      }
    };

    const cardTransaction = ref({
      lastBlock: { slot: true },
      fields: [],
    });

    const currentCardTemplateTransaction = ref({});

    const setTemplate = async () => {
      let templates;
      const enterpriseLs = await hasLocalStorage({ key: 'enterprise' });
      if (enterpriseLs) {
        const { templates: templatesAlias } = enterpriseLs;
        templates = templatesAlias;
      }

      if (templates) {
        // construyo tarjeta para equipos en transaccion
        const templateEquipmentTransactions = templates.filter((val) => val.type_template_id === TYPE_TEMPLATES.TARJETA_EQUIPOS_EN_TRANSACCION)[0];
        if (objEmpty(currentCardTemplateTransaction.value) || currentCardTemplateTransaction.value.id !== templateEquipmentTransactions.id) {
          const templateRequest = JSON.parse(templateEquipmentTransactions.template);
          currentCardTemplateTransaction.value = templateEquipmentTransactions;
          templateRequest.forEach((val) => {
            cardTransaction.value.fields.push(val);
          });
        }
      }
    };
    setTemplate();

    return {
      exit,
      cardTransaction,
      equipments,
      loadingInTransaction,
      removeTransactionEquipment,
      EQUIPMENT_STATES,
      loadingRemove,
    };
  },

};
</script>

<style>

</style>
