<template>
    <div>
      <!-- tabla expres -->
      <Navbar-section  titleClass="ml-4 " section="Equipos gestionados" />
      <div class="flex flex-col justify-center my-2 mx-4">
        <div class=" flex justify-center ">
            <TableExpress
            model="equipments"
            keySearch="search"
            :response="equipments"
            :repository="equipmentManagementIndexRepo"
            :visibleWithOutResponse="false"
            :formFilter="formFilter"
            @notFound="notFound($event)"
            @setResponse="equipments = $event"
            :loaderSearch="true"
            :exportable="true"
            >
              <template v-slot:columns>
                <Column key="id" title="Accion">
                  <template v-slot:action="{content}">
                    <div
                    class="w-22 rounded-full text-white  px-2 py-1 mx-1 bg-gray-400 cursor-pointer"
                    @click="showOptionsEquipment({item:content})" >
                      <font-awesome-icon icon="bars" />
                    </div>
                  </template>
                </Column>
                <Column key="identificacion" :title="$t('Identificacion')" />
                <Column key="equipment_state" title="Estado">
                  <template v-slot:action="{content}">
                    <div :style="`background:${content.equipment_state?.color};color:white;`"
                    :class="['w-22 rounded-lg text-white text-xs p-1 mx-1']">
                      {{content.equipment_state?.name.toLowerCase()}}</div>
                  </template>
                </Column>
                <Column key="" title="Usuario">
                  <template v-slot:action="{content}">
                    <div>
                      {{content.user?.firstName}} {{content.user?.lastName}} {{content.user?.fantasyName}}
                    </div>
                  </template>
                </Column>
                <Column key="created_at" :format="formatDateTime" :title="$t('Fecha')" :fullText="true"/>
                <Column key="accesories" :title="$t('Accesorios')">
                    <template v-slot:action="{content}">
                      <div v-if="content.accesories" class="flex flex-row flex-wrap">
                        <div v-for="(accessory,index) in content.accesories" :key="index" style="min-width:130px;">
                          <span class="text-sm text-gray-400"> {{accessory.accessory.name}} </span>
                          <span class="text-sm font-bold text-gray-500 ml-1"> {{ accessory.value === 1 ? 'si' : 'no'}}</span>
                        </div>
                      </div>
                    </template>
                </Column>
                <Column key="serie" :title="$t('Serie')" />
                <Column key="terminal" :title="$t('Terminal')" />
                <Column key="mac" :title="$t('Mac')" />
                <Column key="serial" :title="$t('Serial')" />
                <Column key="serie_base" :title="$t('Serie base')" />
                <Column key="tarjeta" :title="$t('Tarjeta')" />
                <Column key="otros_accesorios" :title="$t('Otros')" />
                <Column key="equipment.provincia" :title="$t('Provincia')" />
                <Column key="equipment.localidad" :title="$t('Localidad')" />
                <Column key="equipment_id" :title="$t('reff')" />
                <Column key="management_order" :title="$t('Gestión')">
                  <template v-slot:action="{content}">
                    <div
                    v-if="content.management_order.lat && content.management_order.lng"
                    class="text-white rounded  px-2 py-1 mx-1 bg-gray-400 cursor-pointer"
                    @click="onOpenCloseMap(content)" >
                      Mapa
                    </div>
                    <div v-else>
                    </div>
                  </template>
                </Column>
              </template>
            </TableExpress>
        </div>
      </div>

      <!-- opciones de pactado -->
      <Base-modal
      type="auto-screen"
      :headingTitle="$t('Opciones del cliente')"
      :modal="modalOptions"
      @exit="modalOptions = false"
      >
        <template #content>
          <div class="flex flex-col mx-2 my-1">
            <div class="flex flex-col my-1">
              <div>
                Cliente {{currentEquipment.identificacion}}
              </div>
            </div>
            <!-- ver mapa -->
            <template v-if="currentEquipment.equipment_state_id === EQUIPMENT_STATES.RECUPERADO || currentEquipment.equipment_state_id === EQUIPMENT_STATES.AUTORIZAR" >
              <div class="my-1">
              <BaseButton
              @click="openModalSendReceipt()"
              :text="$t('Enviar remito')"
              />
              </div>
              <div class="my-1">
              <BaseButton
              @click="seeReceipt()"
              :text="$t('Ver remito')"
              />
              </div>
            </template>
            <div class="my-1">
            <BaseButton
            @click="confirmDeleteManagement = true"
            :text="$t('Eliminar gestion')"
            :disabled="loading"
            />
            </div>
            <div
            v-if="currentEquipment && currentEquipment.management_order.management_orders_images.length > 0"
            class="my-1">
              <BaseButton
              @click="onOpenListImages"
              :text="$t('Ver imagenes')"
              />
              </div>
          </div>
        </template>
      </Base-modal>

       <!-- envio del remito electronico -->
      <Base-modal
      :modalOverlay="true"
      @exit="exitSendReceipt()"
      :modal="sendReceipt"
      type="media-screen"
      :headingTitle="$t('Enviar')"
      >
        <template #content>
          <div class="main-receip">
            <SendMessage
              title="Enviar remito"
              :email="emailReceipt"
              :phoneNumber="phoneNumberReceipt"
              :loading="loadingSend"
              @exit="exitSendReceipt()"
              @sendEmail="sendEmailReceipt($event)"
              @sendWhatsapp="sendWhatsappReceipt($event)"
              />
          </div>
        </template>
      </Base-modal>

      <Confirm
        headingTitle="$t('¿Estas seguro/a de eliminar? Se eliminaran los datos permanentemente')"
        :modal="confirmDeleteManagement"
        :loadingConfirm="loading"
        :disabledConfirm="loading"
        @exit="confirmDeleteManagement = false"
        @cancel="confirmDeleteManagement = false"
        @confirm="deleteManagement()"
      />

      <!-- ver mapa gestion -->
      <Base-modal
      type="auto-screen"
      :headingTitle="$t('Ubicacion')"
      :modal="showMap"
      @exit="showMap = false"
      >
        <template #content>
          <iframe
          width="100%"
          height="450"
          style="border:0"
          loading="lazy"
          allowfullscreen
          class="mx-auto"
          :src="mapCoordinates"/>
        </template>
      </Base-modal>

      <!-- ver mapa gestion -->
      <Base-modal
      type="full-screen"
      :headingTitle="$t('Imagenes')"
      :modal="showModalOrderImages"
      @exit="showModalOrderImages = false"
      >
        <template #content>
          <div class="flex justify-center flex-row items-center">
            <div
            v-for="(image, index) in currentEquipment.management_order.management_orders_images"
            :key="index"
            class="border border-gray-300 p-1 flex flex-col items-center m-1 rounded-xl"
            >
              <img
                class="w-56 h-56 mx-auto my-2 cursor-pointer"
                :src="`${$apiDevuelvoya}/${image.url}`"
                @click="openGalleryOrderImages(index)"
              >
            </div>
          </div>
          <vue-easy-lightbox
          :visible="visibleGalleryOrderImages"
          :imgs="imageGalleryOrderImages"
          :index="imageIndexOrderImages"
          @hide="visibleGalleryOrderImages = false"
          ></vue-easy-lightbox>
        </template>
      </Base-modal>

    </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router';
// components
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.es5.esm.min';
import SendMessage from '@/components/Form/SendMessage.vue';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';
// utilidades
import usualHook from '@/hooks/core/usualHook';
import { usersInfoRepo } from '@/repositories/User/UserRepository';
import { equipmentManagementIndexRepo, sendReceiptRepo, deleteTransactionRepo } from '@/repositories/Core/EquipmentManagementRepository';
import { CONTACT_CHANNELS } from '@/services/Utils/Utils';
import { EQUIPMENT_STATES } from '@/services/Utils/State';
import { formatDateTime } from '@/services/Utils/Formatters';
import { useI18n } from 'vue-i18n';

export default {
  components: { SendMessage, VueEasyLightbox },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const instance = ref({});

    onMounted(() => {
      instance.value = getCurrentInstance().appContext.config.globalProperties;
    });

    const equipments = ref([]);
    const currentEquipment = ref({});
    const refCardExpress = ref(null);

    const cardSource = ref({
      fields: [
        {
          key: 'identificacion',
          title: 'Identificacion',
        },
        {
          key: 'equipment.nombre_cliente',
          title: 'Nombre',
        },
        {
          key: 'equipment.direccion',
          title: 'Direccion',
        },
        {
          key: 'equipment.localidad',
          title: 'Localidad',
        },
        {
          key: 'equipment.codigo_postal',
          title: 'CP',
        },
        {
          key: 'serie',
          title: 'Serie',
        },
        {
          key: 'terminal',
          title: 'Terminal',
        },
        {
          key: 'serial',
          title: 'Serial',
        },
        {
          key: 'mac',
          title: 'Mac',
        },
        {
          key: 'serie_base',
          title: 'Serie base',
        },
        {
          key: 'tarjeta',
          title: 'tarjeta',
        },
        {
          key: 'chip_alternativo',
          title: 'chip',
        },
        {
          key: 'created_at',
          title: 'Fecha',
        },
        {
          key: 'equipment_state.name',
          title: 'Estado',
        },
        {
          key: 'accesories',
          slot: 'accesories',
        },
      ],
      lastBlock: true,
      windowInnerHeight: window.innerHeight,
    });

    const confirmOpenTransaction = ref(false);
    const loading = ref(false);
    const {
      equipment_states, getEquipmentStates, getEnterprises, enterprises,
    } = usualHook();
    getEquipmentStates();
    getEnterprises();
    const users = ref([]);
    const getUsers = async () => {
      const response = await usersInfoRepo();
      users.value = response;
    };
    getUsers();
    const formFilter = ref(
      {
        equipment_state: {
          placeholder: 'Estado',
          value: null,
          options: equipment_states,
          type: 'select',
        },
        enterprise: {
          placeholder: 'Empresa',
          value: null,
          options: enterprises,
          type: 'select',
        },
        user: {
          placeholder: 'Usuario',
          value: null,
          options: users,
          type: 'select',
          label: 'fullName',
          remove: true,
        },
        createdFrom: {
          placeholder: 'Desde el dia',
          value: null,
          type: 'date',
        },
        createdTo: {
          placeholder: 'Hasta el dia',
          value: null,
          type: 'date',
        },

      },
    );

    const modalOptions = ref(false);
    const showOptionsEquipment = (data) => {
      currentEquipment.value = data.item;
      modalOptions.value = !modalOptions.value;
    };

    const sendReceipt = ref(false);
    const emailReceipt = ref('');
    const phoneNumberReceipt = ref('');

    const openModalSendReceipt = () => {
      sendReceipt.value = !sendReceipt.value;
      const {
        equipment: {
          email, telefono_cel4, telefono_cel5, telefono_cel6,
        },
      } = currentEquipment.value;
      if (email && email !== '') {
        emailReceipt.value = email;
      }
      if (telefono_cel4) {
        phoneNumberReceipt.value = telefono_cel4;
        return;
      }
      if (telefono_cel5) {
        phoneNumberReceipt.value = telefono_cel5;
        return;
      }
      if (telefono_cel6) {
        phoneNumberReceipt.value = telefono_cel6;
      }
    };

    const loadingSend = ref(false);
    const sendEmailReceipt = async (data) => {
      loadingSend.value = true;
      const { email } = data;
      const { management_order_hash, management_order_id } = currentEquipment.value;
      const response = await sendReceiptRepo(management_order_id, management_order_hash, email, CONTACT_CHANNELS.EMAIL);
      const { receipt } = response;
      if (!receipt) {
        instance.value.$toast.error('No se entrego el remito', {
          duration: 2000,
          dismissible: true,
        });
      } else {
        instance.value.$toast.success('Enviado correctamente', {
          duration: 1200,
          dismissible: true,
        });
      }

      loadingSend.value = false;
    };
    const sendWhatsappReceipt = async (data) => {
      loadingSend.value = true;
      const { phoneNumber } = data;
      const { management_order_hash: hash, management_order_id: order } = currentEquipment.value;
      const api = instance.value.$apiDevuelvoya;
      const url = `${api}/v1/receipt/${order}/${hash}`;

      const message = t('whatsapp.receipt_message', {
        order,
        hash,
        url,
      });

      const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, '_blank');

      loadingSend.value = false;
    };

    const emptyResult = ref(false);
    const messageResult = ref('No se han encontrado resultados');
    const notFound = (flag) => {
      emptyResult.value = flag;
      messageResult.value = 'No se han encontrado resultados';
    };

    const exitSendReceipt = () => {
      sendReceipt.value = !sendReceipt.value;
    };

    const seeReceipt = () => {
      const { management_order_id, management_order_hash } = currentEquipment.value;
      window.open(`${instance.value.$apiDevuelvoya}/v1/receipt/${management_order_id}/${management_order_hash}`);
    };

    const confirmDeleteManagement = ref(false);
    const deleteManagement = async () => {
      loading.value = true;
      const { management_order_id, management_order_hash } = currentEquipment.value;
      const response = await deleteTransactionRepo(management_order_id, management_order_hash, 1);
      const { transaction } = response;
      if (transaction) {
        equipments.value = equipments.value.filter((val) => val.management_order_id !== management_order_id && val.management_order_hash !== management_order_hash);
        instance.value.$toast.success('Eliminado correctamente', {
          duration: 2000,
          dismissible: true,
        });
      } else {
        instance.value.$toast.error('No se elimino', {
          duration: 2000,
          dismissible: true,
        });
      }
      loading.value = false;
      confirmDeleteManagement.value = false;
      modalOptions.value = false;
    };

    const showMap = ref(false);
    const mapCoordinates = ref('');
    const onOpenCloseMap = (data) => {
      const { lat, lng } = data.management_order;
      const coordinates = `${lat},${lng}`;
      mapCoordinates.value = `https://www.google.com/maps/embed/v1/place?key=AIzaSyDasdhwGs_A9SbZUezcx9VhSSGkxl46bko&q=${coordinates}`;
      showMap.value = !showMap.value;
    };

    const showModalOrderImages = ref(false);
    const visibleGalleryOrderImages = ref(false);
    const imageIndexOrderImages = ref(0);
    const imageGalleryOrderImages = ref([]);

    const openGalleryOrderImages = (index) => {
      visibleGalleryOrderImages.value = !visibleGalleryOrderImages.value;
      imageIndexOrderImages.value = index;
    };

    const onOpenListImages = () => {
      showModalOrderImages.value = true;
      imageGalleryOrderImages.value = currentEquipment.value.management_order.management_orders_images.map(
        (item) => `${instance.value.$apiDevuelvoya}/${item.url}`,
      );
    };

    return {
      route,
      equipmentManagementIndexRepo,
      equipments,
      cardSource,
      loading,
      confirmOpenTransaction,
      refCardExpress,
      formFilter,
      sendReceipt,
      emailReceipt,
      loadingSend,
      sendEmailReceipt,
      sendWhatsappReceipt,
      exitSendReceipt,
      currentEquipment,
      showOptionsEquipment,
      modalOptions,
      openModalSendReceipt,
      phoneNumberReceipt,
      EQUIPMENT_STATES,
      emptyResult,
      messageResult,
      notFound,
      formatDateTime,
      seeReceipt,
      deleteManagement,
      confirmDeleteManagement,
      onOpenCloseMap,
      showMap,
      mapCoordinates,
      showModalOrderImages,
      onOpenListImages,
      openGalleryOrderImages,
      visibleGalleryOrderImages,
      imageGalleryOrderImages,
      imageIndexOrderImages,
    };
  },
};
</script>

<style>

.run-title{
  width:100%;
  max-width: 180px;

}

</style>
