<template>
    <div class="pb-4">
       <div v-if="loading" class="flex justify-center items-center">
          <LoaderSpinner/>
       </div>

            <!-- numeros de whatsapp procesados -->
            <template v-if="processedPhones.length > 0" >
                <div class="flex flex-row justify-start mx-2 my-2 items-center" >
                    <div>Números disponibles para Whatsapp</div>
                    <div class="whatsapp mx-2">
                        <img :src="`${$apiDevuelvoya}/images/shared/whatsapp-brands.svg`" >
                    </div>
                </div>
                <div  class="flex justify-start flex-row flex-wrap mx-2" >
                    <BaseButton
                    v-for="(phone,index) in processedPhones"
                    :key="index"
                    @click="selectContact(phone)"
                    :text="phone"
                    />
                </div>
                <div class="flex justify-start mx-2 my-2" >
                    <div>
                        Números disponibles para llamar
                    </div>
                    <div class="mx-2">
                        <font-awesome-icon  icon="phone" />
                    </div>
                </div>
                <div  class="flex justify-start flex-row flex-wrap mx-2" >
                    <BaseButton
                    v-for="(phone,index) in processedPhones"
                    :key="index"
                    @click="callNumber(phone)"
                    :text="phone"
                    />
                </div>
            </template>

            <!-- numeros de linea procesados -->
            <template v-if="processedLandLine && processedLandLine.length > 0" >
                <div  class="flex justify-start flex-row flex-wrap mx-2" >
                    <BaseButton
                    v-for="(phone,index) in processedLandLine"
                    :key="index"
                    @click="callNumber(phone)"
                    :text="phone"
                    />
                </div>
            </template>

            <!-- numeros ordinarios  -->
            <template v-if="withoutStandardized && processedNotStandardizedPhones.length > 0" >
                <div v-if="processedPhones.length === 0" class="flex justify-start mx-2 my-2" >
                    <div>Números disponibles</div>
                    <div class="mx-2">
                        <font-awesome-icon  icon="phone" />
                    </div>
                </div>
                <div  class="flex justify-start flex-row flex-wrap mx-1" >
                    <div class="m-1" v-for="(phone,index) in processedNotStandardizedPhones" :key="index">
                      <BaseButton
                      @click="callNumber(phone)"
                      :text="phone"
                      />
                    </div>
                </div>
            </template>

            <!-- emails -->
            <template v-if="(!processedPhones || processedPhones.length < 1) && processedEmails.length > 0" >
                <div  class="flex justify-start flex-row flex-wrap mx-2 my-2" >
                    <BaseButton
                    @click="selectOnlyEmail()"
                    :disabled="loadingEmail || loadingSent"
                    :loading="loadingEmail || loadingSent"
                    :text="$t('Enviar email')"
                    icon="envelope"
                    />
                </div>
            </template>

            <template
            v-if="!loading && processedPhones.length === 0 && processedLandLine.length === 0 &&  processedNotStandardizedPhones.length === 0 && processedEmails.length === 0" >
                <SimpleAlert  color="blue" border="border-b-4 m-2">
                    <template v-slot:text>
                        <div>
                        Este cliente no tiene contactos disponibles para avisos de visita
                        </div>
                    </template>
                </SimpleAlert>
            </template>

        <!-- tipos de aviso -->
        <Base-modal
        :modalOverlay="true"
        @exit="typesOfNotices.display = false"
        :modal="typesOfNotices.display"
        :headingTitle="$t('Tipos de aviso')"
        >
          <template #content>
              <div  class="py-4 mx-2 justify-center flex-col">
                <div @click="sendNotice(VISIT_TYPES.TOMORROW)" class="flex justify-center mx-auto box-type-notice">
                  <img  :src="`${$apiDevuelvoya}/images/shared/tomorrow.png`">
                </div>
                <div class="flex justify-center my-1" >
                  <span ><strong> Aviso del dia siguiente</strong></span>
                </div>
              </div>
              <div class="py-4 mx-2 justify-center flex-col">
                <div @click="sendNotice(VISIT_TYPES.ROUTE)" class="flex justify-center mx-auto box-type-notice">
                  <img  :src="`${$apiDevuelvoya}/images/shared/road.png`">
                </div>
                <div class="flex justify-center my-1" >
                  <span ><strong>Aviso en ruta</strong></span>
                </div>
              </div>
              <div class="py-4 mx-2 justify-center flex-col">
                <div @click="sendNotice(VISIT_TYPES.VISIT)" class="flex justify-center mx-auto box-type-notice">
                  <img  :src="`${$apiDevuelvoya}/images/shared/visitFailed.png`">
                </div>
                <div class="flex justify-center my-1" >
                  <span ><strong>Aviso de visita en puerta</strong></span>
                </div>
              </div>
          </template>
        </Base-modal>
    </div>
</template>
<script>
import LoaderSpinner from '@/components/General/LoaderSpinner.vue';
// utilidades
import { VISIT_TYPES, CONTACT_CHANNELS, getMyCoordinates } from '@/services/Utils/Utils';
//
import { equipmentsIndexRepo } from '@/repositories/Core/EquipmentsRepository';
//
import { sendNoticeRepo } from '@/repositories/Core/EquipmentManagementRepository';

export default {
  components: { LoaderSpinner },
  props: {
    identificacion: {
      type: String,
    },
    emails: {
      type: Array,
      default() {
        return [];
      },
    },
    phoneNumbers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    withoutStandardized() {
      if (this.processedPhones.length === 0 && this.processedLandLine.length === 0) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      phonesToProcess: [],
      processedPhones: [],
      landlineToProcess: [],
      processedLandLine: [],
      NotStandardizedPhonesToProcess: [],
      processedNotStandardizedPhones: [],
      emailsToProcess: [],
      processedEmails: [],
      loading: true,
      loadingSent: false,
      loadingEmail: false,
      gMaps: {
        lat: null,
        lng: null,
      },
      typesOfNotices: {
        display: false,
        to: null,
        contact_channels_id: null,
      },
      firstAttempt: false,
    };
  },
  async created() {
    this.getCustomer();

    // Invocar servicio de geocode
    try {
      const position = await getMyCoordinates();
      const { latitude } = position.coords;
      const { longitude } = position.coords;

      this.gMaps.lat = latitude;
      this.gMaps.lng = longitude;

      // console.log(this.gMaps.lat, this.gMaps.lng);
    } catch (error) {
      // console.log(error);
    }
  },
  methods: {
    async getCustomer() {
      if (this.identificacion) {
        this.loading = true;
        const response = await equipmentsIndexRepo({ ...{ identificacion: this.identificacion }, ...{ cancel: ['recoverable', 'template', 'exists'] } });
        const { equipments } = response;
        const phonesToProcess = await this.gatherAllThePhones(equipments);
        await this.groupPhones(phonesToProcess);
        const landLinesProcess = await this.gatherAllLandline(equipments);
        await this.groupLandLines(landLinesProcess);
        const emailsToProcess = await this.gatherAllTheEmails(equipments);
        await this.cleanEmails(emailsToProcess);
        await this.handleContacts(equipments);
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    gatherAllThePhones(data) {
      return new Promise((resolve) => {
        data.forEach((val) => {
          if (val.telefono_cel4 && val.telefono_cel4 !== '' && val.telefono_cel4 !== null && val.telefono_cel4.length > 6) { this.phonesToProcess.push(val.telefono_cel4.trim()); }
          if (val.telefono_cel5 && val.telefono_cel5 !== '' && val.telefono_cel5 !== null && val.telefono_cel5.length > 6) { this.phonesToProcess.push(val.telefono_cel5.trim()); }
          if (val.telefono_cel6 && val.telefono_cel6 !== '' && val.telefono_cel6 !== null && val.telefono_cel6.length > 6) { this.phonesToProcess.push(val.telefono_cel6.trim()); }
        });

        resolve([...this.phonesToProcess, ...this.phoneNumbers]);
      });
    },
    groupPhones(phonesToProcess) {
      return new Promise((resolve) => {
        if (phonesToProcess) {
          this.processedPhones = phonesToProcess.filter((number, index) => phonesToProcess.indexOf(number) === index);
          // index of te da la posicion
          // si la posicion (indexOf) del numero donde encontro el indexOf es igual a
          // la posicion
          // donde esta realmente el telefono (number,INDEX), lo tomo.
          // Entonces, si la posicion encontrada en el indexOf no es la misma que el index actual
          // del telefono, es porque esta repetido
        }

        resolve(this.processedPhones);
      });
    },
    groupNotStandardizedPhones(phones) {
      return new Promise((resolve) => {
        if (phones) {
          this.processedNotStandardizedPhones = phones.filter((number, index) => phones.indexOf(number) === index);
          // index of te da la posicion
          // si la posicion (indexOf) del numero donde encontro el indexOf es igual a
          // la posicion
          // donde esta realmente el telefono (number,INDEX), lo tomo.
          // Entonces, si la posicion encontrada en el indexOf no es la misma que el index actual
          // del telefono, es porque esta repetido
        }
        resolve(this.processedNotStandardizedPhones);
      });
    },
    gatherAllLandline(data) {
      return new Promise((resolve) => {
        data.forEach((val) => {
          if (val.telefono_fijo1 && val.telefono_fijo1 !== '' && val.telefono_fijo1 !== null && val.telefono_fijo1.length > 6) { this.landlineToProcess.push(val.telefono_fijo1.trim()); }
          if (val.telefono_fijo2 && val.telefono_fijo2 !== '' && val.telefono_fijo2 !== null && val.telefono_fijo2.length > 6) { this.landlineToProcess.push(val.telefono_fijo2.trim()); }
          if (val.telefono_fijo3 && val.telefono_fijo3 !== '' && val.telefono_fijo3 !== null && val.telefono_fijo3.length > 6) { this.landlineToProcess.push(val.telefono_fijo3.trim()); }
        });

        resolve(this.landlineToProcess);
      });
    },
    groupLandLines(landlinesProcess) {
      return new Promise((resolve) => {
        if (landlinesProcess) {
          this.processedLandLine = landlinesProcess.filter((number, index) => landlinesProcess.indexOf(number) === index);
          // index of te da la posicion
          // si la posicion (indexOf) del numero donde encontro el indexOf es igual a
          // la posicion
          // donde esta realmente el telefono (number,INDEX), lo tomo.
          // Entonces, si la posicion encontrada en el indexOf no es la misma que el index actual
          // del telefono, es porque esta repetido
        }

        resolve(this.processedLandLine);
      });
    },
    gatherAllTheEmails(data) {
      return new Promise((resolve) => {
        data.forEach((val) => {
          if (val.email !== '' && val.email !== null && val.email.length > 6) {
            this.emailsToProcess.push(val.email.replace(/ /g, ''));
          }
        });
        resolve([...this.emailsToProcess, ...this.emails]);
      });
    },
    cleanEmails(emails) {
      return new Promise((resolve) => {
        let emailFinal = [];
        let emailClean = [];

        if (emails && emails.length > 0) {
          const emailLimpiosTemporal = [];

          emails.forEach(((email) => {
            if (email.indexOf(';') === -1 && email.indexOf('/') === -1) {
              emailLimpiosTemporal.push(email);
            } else {
              const puntoYComa = ';';
              email.split(puntoYComa).forEach((emailPuntoYComa) => {
                if (emailPuntoYComa.indexOf('/') === -1) {
                  emailLimpiosTemporal.push(emailPuntoYComa);
                } else {
                  const barra = '/';
                  emailPuntoYComa.split(barra).forEach((eBarra) => {
                    emailLimpiosTemporal.push(eBarra);
                  });
                }
              });
            }
          }));

          emailFinal = emailLimpiosTemporal.filter((email, index) => emailLimpiosTemporal.indexOf(email) === index);
          const reg = /@/g;
          emailClean = emailFinal.filter((email) => email.search(reg) > -1);
          this.processedEmails = emailClean;
        }
        resolve(emailClean);
      });
    },
    handleContacts(data) {
      if (this.processedPhones.length === 0 && this.processedLandLine.length === 0) {
        this.gatherAllNotStandardized(data);
      }
    },
    gatherAllNotStandardized(data) {
      const telefono1 = [];
      const telefono2 = [];

      data.forEach((val) => {
        if (val.telefono1) {
          if (val.telefono1.indexOf('/') > -1) {
            const processTelefono1Slash = val.telefono1.split('/');
            if (processTelefono1Slash.length > 0) {
              processTelefono1Slash.forEach((v) => {
                telefono1.push(v);
              });
            }
          } else if (val.telefono1.indexOf('@') > -1) {
            const processTelefono1At = val.telefono1.split('@');
            if (processTelefono1At.length > 0) {
              processTelefono1At.forEach((v) => {
                telefono1.push(v);
              });
            }
          } else if (val.telefono1.indexOf(';') > -1) {
            const processTelefono1Semicolon = val.telefono1.split(';');
            if (processTelefono1Semicolon.length > 0) {
              processTelefono1Semicolon.forEach((v) => {
                telefono1.push(v);
              });
            }
          } else {
            telefono1.push(val.telefono1);
          }
        }

        if (val.telefono2) {
          if (val.telefono2.indexOf('/') > -1) {
            const processTelefono2Slash = val.telefono2.split('/');
            if (processTelefono2Slash.length > 0) {
              processTelefono2Slash.forEach((v) => {
                telefono2.push(v);
              });
            }
          } else if (val.telefono2.indexOf('@') > -1) {
            const processTelefono2At = val.telefono2.split('@');
            if (processTelefono2At.length > 0) {
              processTelefono2At.forEach((v) => {
                telefono2.push(v);
              });
            }
          } else if (val.telefono2.indexOf(';') > -1) {
            const processTelefono2Semicolon = val.telefono2.split(';');
            if (processTelefono2Semicolon.length > 0) {
              processTelefono2Semicolon.forEach((v) => {
                telefono2.push(v);
              });
            }
          } else {
            telefono2.push(val.telefono2);
          }
        }
      });
      this.NotStandardizedPhonesToProcess = telefono1.concat(telefono2);
      this.$nextTick(() => {
        this.groupNotStandardizedPhones(this.NotStandardizedPhonesToProcess);
      });
    },
    selectContact(number) {
      this.typesOfNotices.to = number;
      this.typesOfNotices.contact_channels_id = CONTACT_CHANNELS.WHATSAPP;
      this.typesOfNotices.display = true;
    },
    selectOnlyEmail() {
      this.typesOfNotices.to = this.processedEmails;
      this.typesOfNotices.display = true;
      this.typesOfNotices.contact_channels_id = CONTACT_CHANNELS.EMAIL;
    },
    async sendNotice(type) {
      if (!this.loadingSent) {
        this.loadingSent = true;
        if (this.typesOfNotices.contact_channels_id === CONTACT_CHANNELS.EMAIL) {
          this.loadingEmail = true;
          this.loading = true;
        }
        const object = {
          type_notices_id: type,
          ...this.typesOfNotices,
          identificacion: this.identificacion,
          lat: this.gMaps.lat,
          lng: this.gMaps.lng,
        };
        const response = await sendNoticeRepo(object);
        const { notice } = response;
        if (notice) {
          if (this.typesOfNotices.contact_channels_id === CONTACT_CHANNELS.EMAIL) {
            this.$toast.success('Enviado correctamente', {
              duration: 1500,
              dismissible: true,
            });
          }
          if (this.typesOfNotices.contact_channels_id === CONTACT_CHANNELS.WHATSAPP) {
            window.open(`https://api.whatsapp.com/send?phone=${this.typesOfNotices.to}&text=${notice}`, '_blank');
            if (this.processedEmails.length > 0 && !this.firstAttempt) {
              object.to = this.processedEmails;
              object.contact_channels_id = CONTACT_CHANNELS.EMAIL;
              // trato de enviar siempre avisos de visita por email. El backend revisara si se le debe enviar o no
              await sendNoticeRepo(object);
              this.firstAttempt = true;
            }
          }
        } else {
          this.$toast.error('No fue guardado el envio', {
            duration: 1500,
            dismissible: true,
          });
        }
        this.loadingSent = false;
        this.loadingEmail = false;
        this.loading = false;
      }
    },
    callNumber(number) {
      window.open(`tel:${number}`);
    },
  },
  setup() {
    return { VISIT_TYPES };
  },

};
</script>

<style>
.whatsapp{
    width: 20px
}
.box-type-notice{
  border-radius: 100%;
  padding: 24px 26px;
  background: #eceff1;
  max-width:5.5rem;cursor:pointer;
}
</style>
