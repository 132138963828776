<template>
    <div>
      <CardExpress
      v-if="hasTransaction"
      model="equipments"
      :response="equipmentAvailables"
      :cardSource="cardManagements"
      :repository="equipmentsRepoLocal"
      :timeWaitRequest="700"
      :countVisible="true"
      :navbar="{visible:true, thirdBlock, secondBlock:{ visible: false} }"
      :filters="{identificacion: transaction.identificacion}"
      :cacheParams="false"
      @setResponse="setResponse($event)"
      @clickCard="openModalEquipment($event)"
      @clickNavbarThirdBlock="openModalEquipmentsInTransaction()"
      ref="refEquipments"
      >
        <template v-slot:contentSection>
          <template v-if="hasTransaction">
            <div  class="my-3 flex flex-row justify-between items-center sm:flex-row sm:flex-wrap">
              <div class="mx-2">
                Cliente: {{transaction.identificacion}}
              </div>
              <div class="flex flex-row">
                  <div
                  v-if="checkPermission('managementOrdersImage_save_image')"
                  >
                    <BaseButton
                    @click="onOpenModalPicture"
                    customClass="border border-gray-400  text-sm"
                    icon="camera"
                    />
                  </div>
                  <div class="mx-2">
                      <BaseButton v-if="ROLE.OPERATOR !== auth.user.role_id"
                      @click="openModalEquipment(null, true)"
                      :loading="loadingAutorize"
                      customClass="border border-gray-400 text-sm"
                      padding="p-1"
                      :text="$t('Autorizar')"
                      icon="plus"
                      />
                  </div>
              </div>
            </div>
            <div  class="my-3 flex flex-col justify-center items-center sm:flex-row sm:flex-wrap">
                <!-- finalizar transaccion-->
                <div class="w-56 mw-300" v-if="equipments && equipments.length > 0">
                  <BaseButton
                  @click="handleConfirmation()"
                  customClass="mt-2 border border-gray-400"
                  :text="$t('Confirmar transacción')" />
                </div>
                <!-- cerrar transaccion actual -->
                <div class="w-48 mw-300">
                  <BaseButton
                  @click="confirmRemove = true;"
                  customClass="mt-2 border border-gray-400"
                  :text="$t('Cerrar transaccion')" />
                </div>
            </div>
            <!-- transaccion vacia -->
            <div
            v-if="hasTransaction && equipmentAvailables.length === 0 && !refEquipments?.loading && !booted"
            class="m-2 h-56 flex justify-center items-center flex-col">
              <div class="flex justify-center items-center mb-4">
                <img @click="openModalEquipmentsInTransaction()" style="width:25%;" :src="`${$apiDevuelvoya}/images/shared/package.png`" >
              </div>
            </div>
          </template>
        </template>
      </CardExpress>

      <!-- firma del remito -->
      <Base-modal
      :modalOverlay="true"
      :modal="signtureVisible"
      :headingTitle="$t('Firma')"
      size="md"
      @exit="signtureVisible = false"
      >
        <template #content>
          <div class="flex flex-col">
            <div class="border border-gray-300 flex flex-col">
              <p class="text-center my-1  font-bold">Ingrese firma </p>
              <div class="container">
                <VueSignaturePad
                width="100%"
                height="265px"
                ref="refSignaturePad"
                :options="{penColor: '#000',}"
                />
              </div>
              <!-- borrar firma -->
              <div class="flex justify-center mt-2">
                <div @click="signatureUndo()" class="w-28 h-8 flex justify-center items-center">
                  <font-awesome-icon  class=" text-red-500 text-xl" icon="trash-alt" />
                </div>
              </div>
            </div>
            <!-- aclaracion y documento -->
            <FormExpress
            ref="refForm"
            :inputs="formSignature"
            />
            <div  class="my-2 w-44 mx-1">
              <BaseButton
              @click="confirmTransaction()"
              :disabled="(refForm && !refForm.valid) || loadingConfirm"
              :loading="loadingConfirm"
              customClass="border border-gray-200"
              padding="p-1 px-2"
              background="bg-black"
              textColor="text-white"
              :text="$t('Siguiente')"
              />
            </div>
          </div>
        </template>
      </Base-Modal>

      <!-- salir del transaccion -->
      <Confirm
      :headingTitle="$t('Confirma para salir')"
      :modal="confirmRemove"
      @exit="confirmRemove = false"
      @cancel="confirmRemove = false"
      @confirm="removeTransaction()"
      />
      <!-- confirmar transaccion -->
      <Confirm
      :headingTitle="$t('¿Estas seguro de confirmar transacción?')"
      :modal="confirmTransactionModal"
      :loadingConfirm="loadingConfirm"
      :disabledConfirm="loadingConfirm"
      @exit="confirmTransactionModal = false"
      @cancel="confirmTransactionModal = false"
      @confirm="confirmTransaction()"
      />

      <!-- envio del remito electronico -->
      <Base-modal
      :modalOverlay="true"
      @exit="exitSendReceipt()"
      :modal="modalSendReceipt"
      type="media-screen"
      :headingTitle="$t('Enviar')"
      >
        <template #content>
          <div class="main-receip">
            <SendMessage
              title="Enviar remito"
              :email="emailReceipt"
              :loading="loadingSend"
              @exit="exitSendReceipt()"
              @sendEmail="sendEmailReceipt($event)"
              @sendWhatsapp="sendWhatsappReceipt($event)"
              />
          </div>
        </template>
      </Base-modal>

      <!-- gestion de fotos -->
      <ManagementPhotos
      v-if="showModalPhoto"
      :showModalPhoto="showModalPhoto"
      :management_orders_id="transaction.order"
      @onClose="showModalPhoto = $event"
      />

      <router-view
      @addCounter="refEquipments.addCounter()"
      @subtractCounter="refEquipments.subtractCounter()"
      ></router-view>
    </div>
</template>

<script>
import {
  ref, computed, onMounted, getCurrentInstance,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { agreedEquipmentsRepo } from '@/repositories/Core/AgreementRepository';
import { equipmentsIndexRepo } from '@/repositories/Core/EquipmentsRepository';
import { confirmTransactionRepo, deleteTransactionRepo, sendReceiptRepo } from '@/repositories/Core/EquipmentManagementRepository';
import { TYPE_TEMPLATES } from '@/services/Utils/Templates';
import { EQUIPMENT_STATES } from '@/services/Utils/State';
import { hasLocalStorage, objEmpty } from '@/services/Utils/Objects';
import { ROLE } from '@/services/Utils/Role';
import { CONTACT_CHANNELS, getMyCoordinates } from '@/services/Utils/Utils';
import SendMessage from '@/components/Form/SendMessage.vue';
import ManagementPhotos from '@/views/Equipments/ManagementPhotos.vue';
import { useAuth } from '@/composables/useAuth';
import coreHook from '@/hooks/core/coreHook';
import { useI18n } from 'vue-i18n';

export default {
  components: { SendMessage, ManagementPhotos },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { auth } = useAuth();

    const equipmentAvailables = computed(() => store.state.equipments.equipmentAvailables);
    const currentEquipment = computed(() => store.state.equipments.currentEquipment);
    const enterprise = computed(() => store.state.equipments.enterprise);
    const equipments = computed(() => store.state.equipments.equipments);
    const agreement = computed(() => store.state.equipments.agreement);
    const hasTransaction = computed(() => store.getters['equipments/hasTransaction']);

    const transaction = computed(() => store.state.equipments.transaction);
    const countEquipments = computed(() => store.state.equipments.equipments.length);

    const route = useRoute();
    const router = useRouter();
    const instance = ref({});
    const { checkPermission } = coreHook();

    const lat = ref(null);
    const lng = ref(null);

    const equipmentsRepoLocal = async (params) => {
      const { hash } = route.params;
      if (agreement.value) {
        const { identificacion } = transaction.value;
        const response = await agreedEquipmentsRepo(agreement.value, identificacion, { ...params, ...{ inTransactionManageable: hash } });
        return response;
      }
      const response = await equipmentsIndexRepo({ ...params, ...{ inTransactionManageable: hash }, ...{ cancel: ['recoverable'] } });
      return response;
    };

    onMounted(async () => {
      instance.value = getCurrentInstance().appContext.config.globalProperties;

      // Invocar servicio de geocode
      try {
        const position = await getMyCoordinates();
        const { latitude } = position.coords;
        const { longitude } = position.coords;

        lat.value = latitude;
        lng.value = longitude;
      } catch (error) {
        //
      }
    });
    const refEquipments = ref(null);
    const booted = ref(true);

    const setPropEquipment = async (payload) => {
      const prop = await store.dispatch('equipments/setPropEquipment', payload);
      return prop;
    };

    const needTemplate = ref(false);
    const setResponse = async (response) => {
      booted.value = false;
      if (response.length === 0) {
        // resteo a true porque siempre cuando inicia una busqueda o recarga f5
        // el componente cardExpress devulve primero un array vacio para limpiar todo y luego el response
        needTemplate.value = true;
      }
      if (needTemplate.value && response.length > 0) {
        /* eslint no-use-before-define: ["error", { "variables": false }] */
        setTemplate(response);
        needTemplate.value = false;
      } else {
        // aca recupero el template desde el localstorage porque se reseteo el needTemplate
        setTemplate([], true);
      }
      await store.dispatch('equipments/setPropEquipment', { key: 'equipmentAvailables', value: response });
    };

    const cardManagements = ref({
      // estos campos se muestran por default
      fields: [
        {
          key: 'nombre_cliente',
          title: 'Nombre',
        },
        {
          key: 'direccion',
          title: 'Direccion',
        },
      ],
    });
    const currentCardTemplateManagements = ref({});

    const setTemplate = async (response, templateStore = false) => {
      let templates;
      const [firstCustomer] = response;
      // si entro aqui es para setear los templates desde el response del backend
      if (firstCustomer && !templateStore) {
        const { enterprise: enterpriseAlias } = firstCustomer;
        if (enterpriseAlias) {
          if (objEmpty(enterprise.value)) {
            await setPropEquipment({ key: 'enterprise', value: enterpriseAlias });
            localStorage.setItem('enterprise', JSON.stringify(enterpriseAlias));
          }
          // guardo los datos de la empresa en el localstore y en el vuex para tener el template y datos de la misma siempr disponibles
          const { templates: templatesAlias } = enterpriseAlias;
          templates = templatesAlias;
        }
      } else if (templateStore) {
        const enterpriseLs = await hasLocalStorage({ key: 'enterprise' });
        if (enterpriseLs && objEmpty(enterprise.value)) {
          const { templates: templatesAlias } = enterpriseLs;
          templates = templatesAlias;
        }
      }
      if (templates) {
        // seteamos el template para las tarjetas siempre y cuando sea un template nuevo que viene en el response
        const templateEquipmentManagements = templates.filter((val) => val.type_template_id === TYPE_TEMPLATES.TARJETA_GESTION_EQUIPOS)[0];
        // contruyo las tarjets
        if (objEmpty(currentCardTemplateManagements.value) || currentCardTemplateManagements.value.id !== templateEquipmentManagements.id) {
          const templateRequest = JSON.parse(templateEquipmentManagements.template);
          currentCardTemplateManagements.value = templateEquipmentManagements;
          templateRequest.forEach((val) => {
            cardManagements.value.fields.push(val);
          });
        }
      }
    };

    const handlerCurrentTransaction = async () => {
      const agreement_idLs = await hasLocalStorage({ key: 'agreement' });
      if (agreement_idLs) {
        await setPropEquipment({ key: 'agreement', value: agreement_idLs });
      }
      const { identificacion, order, hash } = route.params;
      const transactionLs = await hasLocalStorage({ key: 'transaction' });
      if (!order || !transactionLs) {
        router.push({ name: 'customers' });
      } else if (transactionLs && (transactionLs.identificacion !== identificacion || transactionLs.hash !== hash || transactionLs.order !== parseInt(order, 0))) {
        router.push({ name: 'customers' });
      } else if (!hasTransaction.value) {
        const transactionStore = {
          identificacion,
          order,
          hash,
        };
        await setPropEquipment({ key: 'transaction', value: transactionStore });

        // verifico si no tengo equipos en el store para buscar en el localstorage y mantenerlos vivos (seteados en el store)
        if (equipments.value.length === 0) {
          const equipmentsLs = await hasLocalStorage({ key: 'equipments' });
          if (equipmentsLs) {
            await setPropEquipment({ key: 'equipments', value: equipmentsLs });
          }
        }
      }
    };
    handlerCurrentTransaction();

    const confirmRemove = ref(false);
    const removeTransaction = async () => {
      // if (equipments.value.length > 0) {
      await deleteTransactionRepo(transaction.value.order, transaction.value.hash);
      // }
      await store.dispatch('equipments/removeTransaction');
      confirmRemove.value = false;

      if (agreement.value) {
        router.push({ name: 'agreementManagements' });
      } else {
        router.push({ name: 'customers' });
      }
    };

    const loadingAutorize = ref(false);
    const openModalEquipment = async (data, autorize = false) => {
      if (!autorize) {
        await setPropEquipment({ key: 'currentEquipment', value: data.item });
        localStorage.setItem('currentEquipment', JSON.stringify(data.item));
        router.push({ name: 'equipmentInTransactionInManagement', params: { equipment_id: data.item.id } });
      } else {
        const currentEquipmentLs = await hasLocalStorage({ key: 'currentEquipment' });
        // el equipo para autorizar debe tener un id_equipo para hacerle seguimiento, no importa que se repita con los que ya
        // estan gestionados
        let equipment_id;
        let equipment;
        if (currentEquipmentLs) {
          equipment_id = currentEquipmentLs.id;
          equipment = currentEquipmentLs;
        } else if (!objEmpty(currentEquipment.value)) {
          equipment_id = currentEquipment.value.id;
          equipment = currentEquipment.value;
        } else {
          const { identificacion } = route.params;
          loadingAutorize.value = true;
          const response = await equipmentsIndexRepo({ identificacion, cancel: ['recoverable', 'template'] });
          loadingAutorize.value = false;
          const { equipments: equipmentsAlias } = response;
          if (equipmentsAlias) {
            const [firstCustomer] = equipmentsAlias;
            equipment_id = firstCustomer.id;
            equipment = firstCustomer;
          }
        }
        await setPropEquipment({ key: 'currentEquipment', value: equipment });
        router.push({ name: 'equipmentInTransactionInManagement', params: { equipment_id, autorize: 1 } });
      }
    };

    const openModalEquipmentsInTransaction = async () => {
      if (agreement.value) {
        router.push({ name: 'agreementTransaction' });
      } else {
        router.push({ name: 'equipmentTransaction' });
      }
    };

    const thirdBlock = computed(() => ({ visible: true, tag: { visible: true, hiddenCount: true, value: countEquipments.value } }));
    const onlyNegatives = ref(false);
    const signtureVisible = ref(false);
    const withSignature = ref(false);
    const refSignaturePad = ref(null);
    // const signature = ref(null);
    const refForm = ref(null);
    const formSignature = ref(
      {
        clarification: {
          placeholder: 'Aclaracion',
          value: null,
          rules: [{ required: true }],
          required: true,
        },
        documentNumber: {
          placeholder: 'Documento',
          value: null,
          rules: [{ required: true }],
          required: true,
        },
      },
    );
    const handleConfirmation = async () => {
      // reviso si hay positivos en la transaccion, si no hay, mando todo al backend de una vez
      const equimentsLs = await hasLocalStorage({ key: 'equipments' });
      if (!equimentsLs.some((val) => val.equipment_state_id === EQUIPMENT_STATES.RECUPERADO || val.equipment_state_id === EQUIPMENT_STATES.AUTORIZAR)) {
        onlyNegatives.value = true;
        confirmTransactionModal.value = true;
      } else {
        const { signature } = enterprise.value;
        if (objEmpty(currentEquipment.value)) {
          const currentEquipmentLs = await hasLocalStorage({ key: 'currentEquipment' });
          if (currentEquipmentLs) {
            await setPropEquipment({ key: 'currentEquipment', value: currentEquipmentLs });
          }
        }
        // del backend viene si tiene que firmar o no el remito
        if (signature) {
          signtureVisible.value = true;
          withSignature.value = true;
          // const { nombre_cliente } = customer;
          // if (nombre_cliente) formSignature.value.clarification.value = nombre_cliente;
        } else {
          confirmTransactionModal.value = true;
        }
      }
    };

    const convertBase64File = (base64, name) => {
      // esto metodo convierte un base64 a archivo, para
      // enviarlo a u backend
      const arr = base64.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      const n = bstr.length;
      const u8arr = new Uint8Array(n);

      for (let i = 0; i < bstr.length; i += 1) {
        u8arr[i] = bstr.charCodeAt(i);
      }

      return new File([u8arr], name, { type: mime });
    };

    const itWasAgreement = ref(false);
    const confirmTransactionModal = ref(false);
    const loadingConfirm = ref(false);
    const confirmTransaction = async () => {
      loadingConfirm.value = true;
      const { identificacion, hash, order } = transaction.value;
      let object = {
        order,
        hash,
        lat: lat.value,
        lng: lng.value,
      };
      if (agreement.value) {
        // si es un pactado, le mando el id del pactado al backend
        object.agreement = agreement.value;
      }

      // si la empresa necesita firmar el remito, verifico que haya firma
      if (withSignature.value) {
        const { isEmpty, data } = refSignaturePad.value.saveSignature();
        if (isEmpty) {
          instance.value.$toast.error('Debes ingresar firma', {
            duration: 2000,
            dismissible: true,
          });
          loadingConfirm.value = false;
          return;
        }
        const img64 = data;
        const image = convertBase64File(img64, `${identificacion}${hash}`);
        object.signature = image;
        const dataFormSignature = { ...await refForm.value.getValues() };
        object = { ...object, ...dataFormSignature };
      }
      const response = await confirmTransactionRepo(object);
      const { transaction: transactionAlias, errors } = response;
      if (!transactionAlias || errors) {
        instance.value.$toast.error('No se guardo correctamente...', {
          duration: 2000,
          dismissible: true,
        });
      } else {
        if (agreement.value) {
          itWasAgreement.value = true;
        }

        instance.value.$toast.success('Confirmada correctamente', {
          duration: 2000,
          dismissible: true,
        });
        signtureVisible.value = false;
        if (!onlyNegatives.value) {
          // debes enviar el remito
          modalSendReceipt.value = true;
          const { email } = currentEquipment.value;
          if (email && email !== '') {
            emailReceipt.value = email;
          }
        } else {
          onlyNegatives.value = true;
          exitSendReceipt();
        }
        await store.dispatch('equipments/removeTransaction');
      }
      loadingConfirm.value = false;
    };
    const signatureUndo = () => {
      refSignaturePad.value.undoSignature();
    };

    const modalSendReceipt = ref(false);
    const emailReceipt = ref('');
    const loadingSend = ref(false);
    const sendEmailReceipt = async (data) => {
      loadingSend.value = true;
      const { email } = data;
      const { hash, order } = route.params;
      const response = await sendReceiptRepo(order, hash, email, CONTACT_CHANNELS.EMAIL);
      const { receipt } = response;
      if (!receipt) {
        instance.value.$toast.error('No se entrego el remito', {
          duration: 2000,
          dismissible: true,
        });
      } else {
        instance.value.$toast.success('Enviado correctamente', {
          duration: 1200,
          dismissible: true,
        });
      }

      loadingSend.value = false;
    };
    const sendWhatsappReceipt = async (data) => {
      loadingSend.value = true;
      const { phoneNumber } = data;
      const { hash, order } = route.params;
      const api = instance.value.$apiDevuelvoya;
      const url = `${api}/v1/receipt/${order}/${hash}`;
      const message = t('whatsapp.receipt_message', { url });
      const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, '_blank');

      loadingSend.value = false;
    };

    const exitSendReceipt = () => {
      if (itWasAgreement.value) {
        router.push({ name: 'agreements' });
      } else {
        router.push({ name: 'customers' });
      }
    };

    const showModalPhoto = ref(false);
    const onOpenModalPicture = () => {
      showModalPhoto.value = !showModalPhoto.value;
    };

    return {
      equipmentsRepoLocal,
      equipmentAvailables,
      setResponse,
      cardManagements,
      setTemplate,
      transaction,
      openModalEquipment,
      hasTransaction,
      removeTransaction,
      openModalEquipmentsInTransaction,
      refEquipments,
      booted,
      equipments,
      thirdBlock,
      countEquipments,
      signtureVisible,
      handleConfirmation,
      refSignaturePad,
      signatureUndo,
      formSignature,
      refForm,
      confirmTransaction,
      confirmRemove,
      modalSendReceipt,
      emailReceipt,
      confirmTransactionModal,
      sendEmailReceipt,
      sendWhatsappReceipt,
      loadingSend,
      exitSendReceipt,
      loadingAutorize,
      loadingConfirm,
      auth,
      checkPermission,
      showModalPhoto,
      onOpenModalPicture,
      ROLE,
    };
  },
};
</script>

<style>

.main-receipt{

    display: flex;
    align-items: center;

}

</style>
