<template>
    <div>
        <CardExpress
        model="equipments"
        keySearch="identificacion"
        :response="equipments"
        :cardSource="cardSource"
        :repository="equipmentsIndexRepo"
        :fetchBooted="false"
        :navbarVisible="true"
        :timeWaitRequest="700"
        :countVisible="true"
        :navbar="{visible:true}"
        :returnObject="true"
        :loaderSearch="true"
        @clickCard="shortcutOpenTransaction($event)"
        @setResponse="setResponse($event)"
        ref="refCardExpress"
        >
          <template v-slot:contentSection>

            <div  v-if="(equipments && equipments.length > 0) || hasTransaction || onlyAutorize" class="my-3 flex flex-col justify-center items-center sm:flex-row sm:flex-wrap">
              <template v-if="hasTransaction">
                <!-- ir a transacciona ctual -->
                <div class="w-60 mw-300">
                  <BaseButton
                  @click="goCurrentTransaction()"
                  :text="$t('Ir a transaccion actual')" />
                </div>
                <!-- cerrar transaccion actual -->
                <div class="w-60 mw-300">
                  <BaseButton
                  @click="removeTransaction()"
                  customClass="mt-2 sm:mt-0 sm:mx-1 border border-gray-400"
                  :text="$t('Cerrar transaccion actual')" />
                </div>
              </template>
              <template v-if="!hasTransaction">
                <!-- iniciar transacccion -->
                <div class="w-60 mw-300">
                  <BaseButton
                  @click="transactionStart()"
                  :loading="loading && !confirmOpenTransaction"
                  :disabled="loading"
                  :text="$t('Iniciar nueva transacción')" />
                </div>
                <!-- aviso de visita -->
                <div class="mt-2 sm:mt-0 sm:mx-1 w-60 mw-300">
                  <BaseButton
                  @click="visitNotices()"
                  :text="$t('Aviso de visita')" />
                </div>
              </template>
            </div>
            <div v-if="equipments.length === 0" class="my-1 flex justify-center  w-100 ">
              <div class="h-56 flex justify-center items-center flex-col">
                  <div class="flex justify-center items-center mb-4">
                    <img style="width:25%;" :src="`${$apiDevuelvoya}/images/shared/rocket.png`" >
                  </div>
                  <div v-if="!hasTransaction" class="text-gray-400  run-title">{{ $t('Ingrese cliente para empezar a gestionar') }}</div>
              </div>
            </div>
            <div v-if="onlyAutorize">
                <SimpleAlert color="gray" border="border-b-4 mx-2">
                    <template v-slot:text>
                      <div>
                        {{ $t('Cliente disponible unicamente para equipos autorizar') }}
                      </div>
                    </template>
                </SimpleAlert>
            </div>
          </template>
        </CardExpress>
        <Confirm
        :headingTitle="$t('¿Deseas iniciar transaccion?')"
        :modal="confirmOpenTransaction"
        :loadingConfirm="loading"
        :disabledConfirm="loading"
        @exit="confirmOpenTransaction = false"
        @cancel="confirmOpenTransaction = false"
        @confirm="transactionStart()"
        />
        <!-- aviso de visita -->
        <Base-modal
        :modalOverlay="true"
        @exit="modalNotice = false"
        :modal="modalNotice"
        type="media-screen"
        :headingTitle="$t('Aviso de visita')"
        >
          <template #content>
            <Notice v-if="modalNotice" :identificacion="refCardExpress.params.identificacion" />
          </template>
        </Base-modal>

    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
// components
import Notice from '@/components/Form/Notice.vue';
// utilidades
import { equipmentsIndexRepo } from '@/repositories/Core/EquipmentsRepository';
//
import { generateOrderRepo } from '@/repositories/Core/EquipmentManagementRepository';
import { hasLocalStorage, objEmpty } from '@/services/Utils/Objects';
import { TYPE_TEMPLATES } from '@/services/Utils/Templates';

export default {
  components: { Notice },
  setup() {
    const store = useStore();
    const countEquipments = computed(() => store.state.equipments.equipments.length);
    const agreement = computed(() => store.state.equipments.agreement);
    const hasTransaction = computed(() => store.getters['equipments/hasTransaction']);
    const setPropEquipment = async (payload) => {
      const prop = await store.dispatch('equipments/setPropEquipment', payload);
      return prop;
    };

    const router = useRouter();
    const route = useRoute();

    const needTemplate = ref(true);
    const equipments = ref([]);
    const refCardExpress = ref(null);
    const onlyAutorize = ref(false);
    const customer = ref({});

    const setResponse = async (data) => {
      const { equipments: equipmentsAlias, exists, pagination } = data;
      if (!objEmpty(data)) {
        if (pagination && pagination.current_page === 1 && equipments.value.length > 0) {
          equipments.value = [];
        }

        if (equipmentsAlias?.length === 0) {
          needTemplate.value = true;
          equipments.value = [];
        } else {
          const [firstCustomer] = equipmentsAlias;
          customer.value = firstCustomer;
        }
        if (needTemplate.value && equipmentsAlias.length > 0) {
        /* eslint no-use-before-define: ["error", { "variables": false }] */
          setTemplate(equipmentsAlias);
          needTemplate.value = false;
          equipments.value = [...equipments.value, ...equipmentsAlias];
        } else if (equipmentsAlias.length > 0) {
          equipments.value = [...equipments.value, ...equipmentsAlias];
        }

        if (equipmentsAlias.length === 0 && (exists && !objEmpty(exists))) {
          onlyAutorize.value = true;
          customer.value = exists;
        }
      }
    };
    const visiblePhoneNumbers = (item) => {
      let result = false;

      if (item && (item.telefono_cel4 || item.telefono_cel5 || item.telefono_cel6)) {
        result = true;
      }
      return result;
    };
    const cardSource = ref({
      fields: [
        {
          key: 'nombre_cliente',
          title: 'Nombre',
        },
        {
          key: 'direccion',
          title: 'Direccion',
        },
        {
          key: 'identificacion',
          title: 'Identificacion',
        },
        {
          onlyTitle: true,
          title: 'Telefonos',
          condition: visiblePhoneNumbers,
        },
        {
          onlyValue: true,
          format: (item) => {
            let result = '';
            if (item.telefono_cel4) {
              result += `${item.telefono_cel4}/`;
            }
            if (item.telefono_cel5) {
              result += `${item.telefono_cel5}/`;
            }
            if (item.telefono_cel6) {
              result += `${item.telefono_cel6}/`;
            }
            if (result.length > 0) {
              if (result.slice(-1) === '/') {
                result = result.substr(0, result.length - 1);
              }
            }
            return result;
          },
        },
      ],
      windowInnerHeight: window.innerHeight,
    });
    const currentTemplate = ref({});
    const setTemplate = (response) => {
      const [firstCustomer] = response;
      const { enterprise } = firstCustomer;
      if (enterprise) {
        const { templates } = enterprise;
        if (templates) {
          const template = templates.filter((val) => val.type_template_id === TYPE_TEMPLATES.TARJETA_GESTION_EQUIPOS)[0];
          if (objEmpty(currentTemplate.value) || currentTemplate.value.id !== template.id) {
            const templateRequest = JSON.parse(template.template);
            currentTemplate.value = template;
            templateRequest.forEach((val) => {
              cardSource.value.fields.push(val);
            });
          }
        }
      }
    };

    const confirmOpenTransaction = ref(false);
    const loading = ref(false);
    const transactionStart = async () => {
      loading.value = true;
      const response = await generateOrderRepo();
      loading.value = false;
      const { order } = response;
      if (order) {
        const { identificacion } = customer.value;
        const transaction = {
          hash: order.hash, identificacion, order: order.id,
        };
        localStorage.setItem('transaction', JSON.stringify(transaction));
        await setPropEquipment({ key: 'transaction', prop: 'identificacion', value: identificacion });
        await setPropEquipment({ key: 'transaction', prop: 'hash', value: order.hash });
        await setPropEquipment({ key: 'transaction', prop: 'order', value: order.id });
        if (onlyAutorize.value) {
          // seteo la empresa desde aca porque cuando entre a la transaccion no va a encontrar el cliente

          localStorage.setItem('enterprise', JSON.stringify(customer.value.enterprise));
        }
        router.push({ name: 'equipmentManagement', params: transaction });
      }
    };
    const shortcutOpenTransaction = () => {
      if (!hasTransaction.value) {
        confirmOpenTransaction.value = true;
      }
    };

    const goCurrentTransaction = () => {
      const { hash, identificacion, order } = store.state.equipments.transaction;
      if (agreement.value) {
        const routeParam = {
          agreement_id: agreement.value,
          identificacion,
          order,
          hash,
        };
        router.push({ name: 'agreementManagement', params: routeParam });
      } else {
        router.push({ name: 'equipmentManagement', params: { hash, identificacion, order } });
      }
    };

    const removeTransaction = () => {
      store.dispatch('equipments/removeTransaction');
    };

    const handleCurrentTransaction = async () => {
      const hasTransactionLs = await hasLocalStorage({ key: 'transaction' });
      if (hasTransactionLs) {
        await setPropEquipment({ key: 'transaction', value: hasTransactionLs });
      }
    };
    handleCurrentTransaction();

    const thirdBlock = computed(() => ({ visible: true, tag: { visible: true, hiddenCount: true, value: countEquipments.value } }));

    const modalNotice = ref(false);
    const visitNotices = () => {
      modalNotice.value = !modalNotice.value;
    };

    return {
      route,
      equipmentsIndexRepo,
      equipments,
      setResponse,
      cardSource,
      setTemplate,
      loading,
      transactionStart,
      visitNotices,
      hasTransaction,
      goCurrentTransaction,
      removeTransaction,
      thirdBlock,
      onlyAutorize,
      confirmOpenTransaction,
      shortcutOpenTransaction,
      modalNotice,
      refCardExpress,
    };
  },
};
</script>

<style>

.run-title{
  width:100%;
  max-width: 180px;

}

</style>
