import { reactive } from 'vue';

export function imageHook() {
  // esta version solo puede renderizar una imagen por vez, si utilizas esto adentro
  // de un foreach no funcionara correctamente con la imagen siguiente.
//   Lo mas recomendable para renderizar array de imagenes es enviarla al servidor
//   guardarlas, devolverlas y consumirlas
  const image = reactive({
    readyState: 0,
    image: {
      base64: '',
      file: null,
    },
  });

  const processImage = (file) => {
    if (file !== undefined && file !== '') {
      const reader = new FileReader();
      image.readyState = reader.readyState;
      reader.onload = async (e) => {
        image.readyState = reader.readyState;
        image.image = {
          base64: e.target.result,
          file,
        };
      };
      image.readyState = reader.readyState;
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    image.readyState = 0;
    image.image.base64 = '';
    image.image.null = null;
  };

  return { image, processImage, removeImage };
}
