'strict mode';

import $http from '@/services/Api/AxiosInstance';

export const equipmentsIndexRepo = async (params = {}) => {
  let result = {};
  try {
    const object = {
      ...{ recoverable: 1 }, ...params, ...{ template: 1 }, ...{ exists: 1 },
    };
    if (Object.prototype.hasOwnProperty.call(object, 'cancel')) {
      object.cancel.forEach((val) => {
        delete object[val];
      });
      delete object.cancel;
    }

    const response = await $http.get('v1/equipments', { params: object });
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};
