<script setup>
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.es5.esm.min';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';
import {
  ref, defineProps, toRefs, defineEmits, onMounted, getCurrentInstance,
} from 'vue';
import { useToast } from 'vue-toast-notification';
import { imageHook } from '@/hooks/imageHook';
import {
  saveManagementOrdersImageRepo,
  getOrderImagesRepo,
  deleteOrderImageRepo,
} from '@/repositories/Core/EquipmentManagementRepository';

const $toast = useToast();
// import { convertFileToRender } from '../../services/Utils/Image';

const props = defineProps({
  showModalPhoto: {
    type: Boolean,
  },
  management_orders_id: {
    type: [Number, String],
  },
});

const { showModalPhoto, management_orders_id } = toRefs(props);
const emit = defineEmits(['onClose']);

const instance = ref({});
onMounted(() => {
  instance.value = getCurrentInstance().appContext.config.globalProperties;
});

const photos = ref({});
const visibleGallery = ref(false);
const imageIndex = ref(0);
const imageGallery = ref([]);

// const renderPhoto = ref({});
const { image, processImage, removeImage } = imageHook();
const showImg = () => {
  imageGallery.value = [image.image.base64];
  imageIndex.value = 0;
  visibleGallery.value = true;
};

const handlePhotos = (dataImage) => {
  if (dataImage?.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    photos.value = dataImage[0];
    processImage(dataImage[0]);
  }
};

const resetContext = () => {
  photos.value = {};
  visibleGallery.value = false;
  imageGallery.value = [];
  removeImage();
};

const handleClose = () => {
  emit('onClose', false);
  resetContext();
};

const loading = ref(false);
const saveImage = async () => {
  if (!loading.value) {
    loading.value = true;
    const response = await saveManagementOrdersImageRepo({
      image: photos.value,
      management_orders_id: management_orders_id.value,
    });
    const { image: imageBack, errors } = response;
    if (errors || !imageBack) {
      loading.value = false;
      $toast.error('No se ha guardado correctamente');
    } else {
      loading.value = false;
      emit('onClose', false);
      resetContext();
      $toast.success('Guadada correctamente');
    }
  }
  // console.log(save);
};

const showModalListImages = ref(false);
const listImages = ref([]);
const visibleGalleryOrderImages = ref(false);
const imageIndexOrderImages = ref(0);
const imageGalleryOrderImages = ref([]);

const loadingImages = ref(false);
const getImages = async () => {
  if (loadingImages.value) return;
  loadingImages.value = true;
  const response = await getOrderImagesRepo(management_orders_id.value);
  loadingImages.value = false;
  const { managementOrdersImages } = response;
  listImages.value = managementOrdersImages;
  imageGalleryOrderImages.value = listImages.value.map(
    (item) => `${instance.value.$apiDevuelvoya}/${item.url}`,
  );
};
const openGalleryOrderImages = (index) => {
  visibleGalleryOrderImages.value = !visibleGalleryOrderImages.value;
  imageIndexOrderImages.value = index;
};
const onOpenModalListImages = () => {
  showModalListImages.value = !showModalListImages.value;
  getImages();
};

const loadingImage = ref(false);
const deleteImage = async (id) => {
  if (loadingImage.value) return;
  loadingImage.value = true;
  const result = await deleteOrderImageRepo(id);
  const { message } = result;
  if (message === 'Deleted successfully') {
    $toast.success('Eliminado correctamente');
  } else {
    $toast.error('No se elimino correctamente');
  }
  loadingImage.value = false;
  getImages();
};

</script>
<template>
    <!-- tomar foto -->
    <div>
        <Base-modal
        :modalOverlay="true"
        @exit="handleClose"
        :modal="showModalPhoto"
        type="full-screen"
        :headingTitle="$t('Tomar foto')"
        >
          <template #content>
            <div class="flex justify-center flex-col items-center">
              <div v-if="image && image.image?.base64">
                <BaseButton
                @click="saveImage"
                customClass="my-2 rounded-xl "
                background="bg-green-400 "
                textColor="text-white"
                :text="$t('Guardar imagen')"
                :loading="loading"
                />
              </div>
              <div class="" v-if="image && image.image?.base64">
                <img
                class="cursor-pointer"
                :src="image.image.base64"
                @click="showImg()"
                />
              </div>

              <div class="my-2" >
                <BaseFile
                :value="photos"
                :accept="[
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'capture=camera'
                ]"
                @onChange="handlePhotos($event)"
                >
                  <template #full>
                    <div>
                      Tomar foto
                    </div>
                  </template>
                </BaseFile>
              </div>
              <BaseButton
                @click="onOpenModalListImages"
                customClass="my-2 rounded-xl"
                background="bg-red-400 "
                textColor="text-white"
                :text="$t('Ver fotos')"
              />
            </div>
            <Base-modal
            :modalOverlay="true"
            @exit="showModalListImages = false"
            :modal="showModalListImages"
            type="full-screen"
            :headingTitle="$t('Lista de fotos')"
            >
            <template #content>
              <div class="flex justify-center flex-col items-center">
                <div v-if="listImages.length === 0 && !loadingImages">
                  Aun no hay imagenes cargadas
                </div>
                <div
                v-for="(image, index) in listImages"
                :key="index"
                class="border border-gray-300 p-1 flex flex-col items-center my-1 rounded-xl"
                >
                  <img
                    class="w-56 mx-auto my-2 cursor-pointer"
                    :src="`${$apiDevuelvoya}/${image.url}`"
                    @click="openGalleryOrderImages(index)"
                  >
                  <div class="flex items-center m-2">
                    <template v-if="loadingImage">
                      <svg class="animate-spin w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </template>
                    <font-awesome-icon
                    v-else
                    class="cursor-pointer items-center align-center mx-2"
                    icon="trash-alt"
                    @click="deleteImage(image.id)"
                    />
                  </div>
                </div>
              </div>
              <vue-easy-lightbox
              :visible="visibleGalleryOrderImages"
              :imgs="imageGalleryOrderImages"
              :index="imageIndexOrderImages"
              @hide="visibleGalleryOrderImages = false"
              ></vue-easy-lightbox>
            </template>
          </Base-modal>
          </template>
        </Base-modal>
        <vue-easy-lightbox
        :visible="visibleGallery"
        :imgs="imageGallery"
        :index="imageIndex"
        @hide="visibleGallery = false"
        ></vue-easy-lightbox>
    </div>
</template>
